/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

$primaryColor: #F7D000;
$primaryColorDarken: #ffd600;


/* -------------------------------------------------------------------------- */
/*                             ANCHOR FONT WEIGHT                             */
/* -------------------------------------------------------------------------- */

@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: $i * 100;
  }
}

/* -------------------------------------------------------------------------- */
/*                             ANCHOR BACKGROUNDS                             */
/* -------------------------------------------------------------------------- */
.bg-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-machine {
  background-image: url('./assets/background.jpg');
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR BUTTONS                               */
/* -------------------------------------------------------------------------- */

.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: black;
  height: 45px;
}

.btn-primary:hover {
  background-color: $primaryColorDarken;
  border-color: $primaryColorDarken;
  color: black;
}

.form-control {
  height: 50px;
}